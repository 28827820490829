import React from 'react';

const About = () => {
    return (
        <section className="w-3/4 mx-auto text-[#2f4a69] font-[Roboto] text-xl my-3 mb-8">
            <div className="text-center ">
                <div>
                    <span>База риболовлі та сімейного відпочинку Деметра відкриває сезон у 2024 році.</span><br/>
                    <span>Завжди раді вас бачити!</span>
                </div>
                <div>
                    <h2 className="font-[Poppins] text-[38px] my-3">
                        <p className="text-[#ffd710] pb-3">Все буде </p>
                        <p className="text-[#0a95d5]">УКРАЇНА!!!!</p>
                    </h2>
                    <h4>Ласкаво просимо до нас</h4>
                </div>
            </div>

            <div className="mt-8">
                <p className="indent-8 ">
                    База "Деметра" облаштована всім необхідним для відпочинку. Тут не тільки номери, обладнані свіжим
                    ремонтом,
                    але й ландшафтний дизайн для комфортних прогулянок. Саме головне - це природа та краєвиди, які можна
                    побачити тільки у нас.
                    Ваш комфорт буде забезпечений спуском та підʼйомом човнів і їх охороною під час перебування на базі.
                    Також є можливість залишити ваш човен в критому ангарі до наступного приїзду.
                </p><br/>
                <p className="indent-8">
                    Досвідчений єгер допоможе вам спіймати саму велику рибу, яка є у водоймі, якщо фарт буде на вашому
                    боці.
                </p><br/>
                <p className="indent-8">
                    Кожний номер обладнаний кондиціонером, є холодильник, мікрохвильова піч, варильна поверхня, телевізор, бойлер. Опалення
                    тепла підлога.
                    В кухні в наявності усе приладдя для приготування їжі. Уся територія під відеоспостереженням.
                </p><br/>
                <p className="indent-8">
                    База риболовлі та сімейного відпочинку Деметра знаходиться на лівому березі басейну
                    річки Південний Буг між селами Піски та Гурʼївка, близько 25 км від Миколаєва.
                    Це місце приваблює риболовів та поцінувачів тихого та культурного відпочинку.
                    Вас чекають комфортабельні номери, затишок та вдала рибооловля.
                    Досвідчений єгер допоможе вам спіймату саму велику рибу, яка є у водоймі, якщо фарт буде на
                    вашому боці.
                </p>
            </div>
        </section>
    );
};

export default About;